














































































































































/**
 * Show all recorded calls
 */
import Vue from "vue";
import RecordPlayer from "./RecordPlayer.vue";
import NotRecords from "@/components/NotRecords.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { CallList } from "@/mixins";
import RecordedCall from "@/models/RecordedCall";

export default Vue.extend({
  name: "recorded-calls",
  mixins: [CallList],
  components: {
    RecordPlayer,
    NotRecords,
  },
  data() {
    return {
      footerProps: { "items-per-page-options": [15, 50, 100] },
      options: {},
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        record: true,
      },

      expanded: [],
      file: "",
      admin: false,
      showPlayer: false,
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mounted() {
    (this as any).mutView("rec");
    (this as any).getCalls();
    if (this.isAdmin || this.isSuper) {
      this.admin = true;
    } else {
      this.admin = false;
    }
  },
  computed: {
    ...mapState("crmCallCenterModule", [
      "recordList",
      "totalrecord",
      "requestC",
      "filter",
    ]),
    ...mapGetters(["isSuper", "isAdmin", "getPhone", "getUser"]),
  },
  watch: {
    options(val) {
      if (val != {}) {
        (this as any).getCalls();
      }
    },
  },
  destroyed() {
    (this as any).mutRequest(null);
    (this as any).mutFilter(false);
  },
  methods: {
    ...mapActions("crmCallCenterModule", ["actGetCallsRec"]),
    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
    ]),
    ...mapMutations(["mutReqRange"]),
    playRecord(file: string): void {
      this.file = file;
      this.showPlayer = true;
    },

    getFrom(call: RecordedCall) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.from, call);
      }
    },
    getTo(call: RecordedCall) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return (this as any).cleanPhone(call.to, call);
      }
    },

    getCalls() {
      const { page, itemsPerPage }: any = (this as any).options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;

      let body = {
        range: { limit: itmper, offset: (page - 1) * itmper },
        record: (this as any).reqbody.record,
      };
      if (this.filter) {
        body = this.requestC;
      }

      (this as any).mutRequest(body);
      (this as any).actGetCallsRec(body);
    },
    removeRecord(item: RecordedCall) {
      (this as any).setRecordToRemove(item);
      this.showPlayer = false;
    },

    _origin(item: RecordedCall) {
      if (item.patient_name != null) {
        return "PATIENT";
      }
      if (item.l_phone != null) {
        return "LEAD";
      }
      if (item.employee_fullname != null) {
        return "EMPLOYEE";
      }
      return "-";
    },
    _name(item: RecordedCall) {
      if (item.patient_name != null) {
        return item.patient_name;
      }
      if (item.l_name != null) {
        return item.l_name;
      }

      if (item.employee_fullname != null) {
        return item.employee_fullname;
      }
      return "-";
    },
  },
});
