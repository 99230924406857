





























































































import { notifyError } from "@/components/Notification";
import Vue from "vue";
import colors from "vuetify/lib/util/colors";
// import Cursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor";

export default Vue.extend({
  name: "record-player",
  props: {
    file: String,
  },
  data() {
    return {
      playerSpeed: 1,
      playing: false,
      loading: 0,
      showSpeedControl: false,
      showVolumeControl: false,
      volume: 70,
      loadginError: false,
      waveSurferOptions: {
        options: {
          // plugins: [
          //   Cursor.create({
          //     showTime: true,
          //     opacity: 1,
          //     color: colors.blue.base,
          //     customShowTimeStyle: {
          //       "background-color": colors.blue.base,
          //       color: "#fff",
          //       padding: "2px",
          //       "font-size": "10px",
          //       "border-radius": "0 10px 10px 0",
          //     },
          //   }),
          // ],
          waveColor: colors.grey.lighten1,
          progressColor: colors.grey.darken1,
          cursorColor: colors.blue.base,
          barWidth: 3,
          barRadius: 3,
          cursorWidth: 1,
          height: 94,
          barGap: 3,
        },
      },
    };
  },
  computed: {
    player() {
      return (this.$refs.waveform as any).waveSurfer;
    },
    disableControls(): boolean {
      return this.loading < 100 || this.loadginError;
    },
  },
  watch: {
    file(file: string) {
      this.player.stop();
      this.player.load(file);
    },
  },
  mounted() {
    this.initPlayer();
  },
  methods: {
    initPlayer(): void {
      this.player.on("loading", (progress: number) => {
        this.loading = progress;

        if (progress > 99) {
          this.player.stop();
          this.playing = false;
          this.statePause();
          this.loadginError = false;
        }
      });
      this.player.on("finish", () => {
        this.statePause();
        this.playing = false;
      });
      this.player.on("error", () => {
        this.loadginError = true;
        notifyError(this.$t("loadError") as string);
      });
    },
    play() {
      if (this.playing == false) {
        this.statePlaying();
        this.player.play();
      } else {
        this.statePause();
        this.player.pause();
      }

      this.playing = !this.playing;
    },
    setPlaybackRate(rate: number) {
      const player = (this.$refs.waveform as any).waveSurfer;
      const values = [0.5, 1, 2];

      player.setPlaybackRate(values[rate]);
    },
    skipBackward() {
      this.player.skipBackward();
    },
    skipForward() {
      this.player.skipForward();
    },
    setVolume(vol: number) {
      this.player.setVolume(vol / 100); // [0..1];
    },
    toggleVolumenControl() {
      this.showVolumeControl = !this.showVolumeControl;
      this.showSpeedControl = false;
    },
    toggleSpeedControl() {
      this.showSpeedControl = !this.showSpeedControl;
      this.showVolumeControl = false;
    },
    statePlaying(): void {
      const iconPlay = (this.$refs.iconPlay as any).$el;
      const iconPause = (this.$refs.iconPause as any).$el;

      (this as any).$anime({
        targets: iconPause,
        rotate: ["0deg", "45deg"],
        opacity: [1, 0],
        scale: [1, 0],
      });
      (this as any).$anime({
        targets: iconPlay,
        rotate: ["45deg", "0deg"],
        opacity: [0, 1],
        scale: [0, 1],
      });
    },
    statePause(): void {
      const iconPlay = (this.$refs.iconPlay as any).$el;
      const iconPause = (this.$refs.iconPause as any).$el;

      (this as any).$anime({
        targets: iconPlay,
        rotate: ["0deg", "45deg"],
        opacity: [1, 0],
        scale: [1, 0],
      });
      (this as any).$anime({
        targets: iconPause,
        rotate: ["45deg", "0deg"],
        opacity: [0, 1],
        scale: [0, 1],
      });
    },
  },
});
